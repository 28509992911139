<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="标签 :">
          <el-select v-model="queryForm.tag" clearable>
            <el-option
              v-for="item in tagsList"
              :key="item.id"
              :label="item.tag"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属用户 :" v-if="userInfo.is_staff">
          <el-input
            v-model="queryForm.owner"
            placeholder="所属用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="avatar" label="头像" width="60">
            <template v-slot="scope">
              <img
                width="29px"
                height="29px"
                v-if="scope.row.avatar !== null"
                :src="scope.row.avatar"
                alt=""
              />
              <img
                width="29px"
                height="29px"
                v-else
                src="../../assets/imgs/mrtx.png"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="tag.tag" label="标签" width="150">
            <template v-slot="scope">
              <el-tag
                size="mini"
                type="success"
                v-if="scope.row.tag.tag !== null"
              >
                {{ scope.row.tag.tag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in headerList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
          <el-table-column
            label="标记时间"
            prop="create_time"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="操作" width="160">
            <template v-slot="scoped">
              <el-button @click="handleDel(scoped.row.id)" type="text">
                取消标记
              </el-button>
              <el-button
                @click="$router.push('/user/detail/' + scoped.row.user.id)"
                type="text"
              >
                用户检索
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserTagList, delUserTag, getTagAll } from '@/api/tagged'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getManagerStatus } from '@/utils/manager'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'UserTag',
  mixins: [cacheMixin],
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      multipleSelection: [],
      queryForm: {
        page_index: 1,
        page_size: 10,
        owner: '',
        tag: this.$route.query.tag,
        ordering: '-create_time'
      },
      headerList: [
        {
          label: '用户ID',
          prop: 'user.id'
        },
        {
          label: '用户名称',
          prop: 'user.name'
        },
        {
          label: '电话号码',
          prop: 'user.phone'
        }
        // {
        //   label: '标记时间',
        //   prop: 'create_time'
        // }
      ],
      tagsList: []
    }
  },
  created() {
    this.fetchData()
    this.getTagsList()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    // 获得所有标签
    async getTagsList() {
      const res = await getTagAll()
      this.tagsList = res
    },
    async fetchData() {
      this.listLoading = true
      const res = await getUserTagList(this.queryForm)

      this.count = res.count
      this.list = res.items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    // 取消标记
    handleDel(id) {
      this.$confirm('此操作将取消该标记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delUserTag(id)
          this.fetchData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }
      console.log(column, prop, order)
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 35px);
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
